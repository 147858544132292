export class Captcha {
    private context: CanvasRenderingContext2D;
    private validCode: string;

    constructor(private canvas: HTMLCanvasElement, private width = 170, private height = 40) {

        this.canvas.width = this.width;
        this.canvas.height = this.height;

        this.context = this.canvas.getContext('2d');

        this.renderCaptcha();
    }

    isValidCode(code: string): boolean {
        return this.validCode.toLowerCase() === code.toLowerCase();
    }

    renderCaptcha(numberOfCharacters = 5): void {
        const validCharacters = [];

        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const chainOfCharacters = characters.split('');
        const characterLength = chainOfCharacters.length;

        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

        for (let i = 0; i <= numberOfCharacters; i++) {
            const charIndex = Math.floor(Math.random() * characterLength);
            const rotation = (Math.random() * 30 * Math.PI) / 180;
            const char = chainOfCharacters[charIndex];

            validCharacters[i] = char.toLowerCase();

            const x = 10 + i * 20;
            const y = 20 + Math.random() * 8;

            this.context.font = 'bold 23px 微软雅黑';
            this.context.translate(x, y);
            this.context.rotate(rotation);

            this.context.fillStyle = this.generateColor();
            this.context.fillText(char, 0, 0);

            this.context.rotate(-rotation);
            this.context.translate(-x, -y);
        }

        for (let i = 0; i <= 3; i++) {
            this.context.strokeStyle = this.generateColor();
            this.context.beginPath();
            this.context.moveTo(
                Math.random() * this.width,
                Math.random() * this.height
            );
            this.context.lineTo(
                Math.random() * this.width,
                Math.random() * this.height
            );
            this.context.stroke();
        }

        for (let i = 0; i < 30; i++) {
            this.context.strokeStyle = this.generateColor();
            this.context.beginPath();
            const x = Math.random() * this.width;
            const y = Math.random() * this.height;
            this.context.moveTo(x, y);
            this.context.lineTo(x + 1, y + 1);
            this.context.stroke();
        }

        this.validCode = validCharacters.join('');
    }

    private generateColor(): string {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgb(${r}, ${g}, ${b})`;
    }
}


export default (containerSelector: string): [Captcha, HTMLInputElement] => {
    const container = document.querySelector(containerSelector);

    const canvas = container.querySelector('canvas');
    const resetButton = container.querySelector('.captcha-reset');
    const input: HTMLInputElement = container.querySelector('.captcha-input');

    const captcha = new Captcha(canvas);

    resetButton.addEventListener('click', _ => captcha.renderCaptcha());

    return [captcha, input];
}