export default class SmoothScroll {
    static isPolyfillLoaded = false;

    constructor(private offset: number = 0) {
        this.loadPolyfillIfNeeded();

        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', e => {
                e.preventDefault();

                const targetSelector = anchor.getAttribute('href');

                this.scrollTo(targetSelector);
            });
        });
    }

    private scrollTo(selector: string): void {
        const target = document.querySelector(selector);

        if (!target) return;

        const top = target.getBoundingClientRect().top + window.pageYOffset + this.offset;

        window.scrollTo({
            top,
            behavior: 'smooth'
        });
    }

    private loadPolyfillIfNeeded = async (): Promise<void> => {
        if (SmoothScroll.isPolyfillLoaded) return;

        if (!('scrollBehavior' in document.documentElement.style)) {
            const smoothScrollPolyfill = await import('smoothscroll-polyfill');
            smoothScrollPolyfill.polyfill();
        }

        SmoothScroll.isPolyfillLoaded = true;
    }
}
