import './styles/main.scss'
import Tab from './scripts/tab';
import Calculator from './scripts/calculator';
import SmoothScroll from './scripts/smooth-scroll';
import ContactForm from './scripts/contact-form';
import Nav from './scripts/nav'

window.addEventListener('load', () => {
    new Nav('#nav');

    new ContactForm('#contact form', '#contact-error');

    new Tab('.tab');

    new Calculator('#calculator');

    const navigation = document.querySelector('#nav');
    const scrollOffsetY = -navigation.clientHeight;
    new SmoothScroll(scrollOffsetY);

    import('@splidejs/splide').then(({ default: Splide }) => {
        new Splide('#application-slideshow', {
            type: 'loop',
            perPage: 3,
            focus: 'center',
            arrows: false,
            autoplay: true,
            breakpoints: {
                1200: {
                    perPage: 2
                },
                996: {
                    perPage: 1,
                    focus: false
                }
            }
        }).mount();
    });
});
