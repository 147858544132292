let translation: { [key: string]: string } = {};

try {
    const translationElement: HTMLElement = document.querySelector('[data-translations]');
    const json = translationElement?.dataset.translations ?? '{}';
    translation = JSON.parse(json);
} catch (error) {
    console.error(error);
    translation = {};
}

export default (key: string): string => {
    return translation[key] ?? key;
};