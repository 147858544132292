import Collapse from 'bootstrap/js/dist/collapse';

new Collapse('[data-bs-toggle="collapse"]');

export default class Nav {
    constructor(selector: string) {
        const nav = document.querySelector(selector);

        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                nav.classList.add('active');
            } else {
                nav.classList.remove('active');
            }
        }, {
            passive: true
        });
    }
}
