export default class Tab {
    private links: NodeListOf<HTMLElement>;
    private contents: NodeListOf<HTMLElement>;

    constructor(selector: string) {
        const container: HTMLElement = document.querySelector(selector);

        if (!container) return;

        this.links = container.querySelectorAll('[data-tab-link-id]');
        this.contents = container.querySelectorAll('[data-tab-id]');

        this.links.forEach(link => {
            link.addEventListener('click', () => {
                const id = Number(link.dataset.tabLinkId);

                this.activateTab(id);
            });
        });
    }

    private activateTab(id: number): void {
        this.links.forEach(link => {
            if (Number(link.dataset.tabLinkId) == id) {
                link.classList.add('active');
            } else {
                link.classList.remove('active');
            }
        });

        this.contents.forEach(content => {
            if (Number(content.dataset.tabId) == id) {
                content.classList.add('active');
            } else {
                content.classList.remove('active');
            }
        });
    }
}