import __ from './translator';
import generateCaptcha, { Captcha } from './captcha';

export default class ContactForm {
    form: HTMLFormElement;
    errorElement: HTMLElement;
    sendingForm = false;
    captcha: Captcha;
    captchaInput: HTMLInputElement;

    constructor(formSelector: string, errorSelector: string) {
        this.form = document.querySelector(formSelector);
        this.errorElement = document.querySelector(errorSelector);

        this.form.addEventListener('submit', this.onSubmit.bind(this));

        const [captcha, captchaInput] = generateCaptcha('[data-captcha]');

        this.captcha = captcha;
        this.captchaInput = captchaInput;
    }

    private async onSubmit(event: Event) {
        event.preventDefault();

        if (!this.captcha.isValidCode(this.captchaInput.value)) {
            this.showError(__('Invalid Captcha'));
            this.captchaInput.value = '';
            this.captcha.renderCaptcha();
            this.captchaInput.focus();
            return;
        }

        if (this.sendingForm) return;

        try {
            this.sendingForm = true;
            this.hideError();

            const response = await fetch(this.form.action, {
                method: 'POST',
                body: new FormData(this.form)
            });

            const body = await response.text();

            if (response.status === 200) {
                this.showSuccess(body);
            } else if (response.status >= 400 && response.status < 500) {
                this.showError(body);
            } else {
                this.showError(__('Sorry, Something went wrong'));
            }

        } catch (error) {
            this.showError(__('Sorry, Something went wrong'));
            console.error(error);

        } finally {
            this.sendingForm = false;
        }
    }

    private showError(errorMarkup: string) {
        this.errorElement.innerHTML = errorMarkup;
    }

    private showSuccess(markup: string) {
        this.form.outerHTML = markup;
    }

    private hideError() {
        this.errorElement.innerHTML = '';
    }
}