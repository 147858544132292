
type calculatorStepKey = 'wage' | 'hours' | 'resultHours' | 'employees';
type calculatorResultKey = 'laborCost' | 'cost' | 'benefits' | 'extra' | 'benefitsPerYear';


interface CalculatorStepState {
    wage: number;
    hours: number;
    resultHours: number;
    employees: number;
}

interface CalculatorResultState {
    laborCost: number;
    cost: number;
    benefits: number;
    extra: number;
    benefitsPerYear: number;
}

export default class Calculator {
    private steps: HTMLInputElement[] = [];
    private results: HTMLInputElement[] = [];

    private state: CalculatorStepState = {
        wage: 0,
        hours: 0,
        resultHours: 0,
        employees: 0
    };

    private resultState: CalculatorResultState = {
        laborCost: 0,
        cost: 0,
        benefits: 0,
        extra: 0,
        benefitsPerYear: 0
    };

    constructor(selector: string) {
        const container: HTMLElement = document.querySelector(selector);

        if (!container) return;

        this.steps = Array.from(container.querySelectorAll('[data-calculator-step]'));
        this.results = Array.from(container.querySelectorAll('[data-calculator-result]'));

        this.initializeState();
    }

    private initializeState(): void {
        this.steps.forEach(step => {
            step.addEventListener('input', this.updateState.bind(this));
        });

        this.updateState();
    }

    private updateState(): void {
        this.steps.forEach(step => {
            const stepType = step.dataset.calculatorStep as calculatorStepKey;
            this.state[stepType] = this.toFloat(step.value);
        });

        this.calculateState();

    }

    private calculateState() {
        this.resultState.laborCost = (this.state.wage * (this.state.hours + this.state.resultHours)) * this.state.employees;
        this.resultState.cost = 208;
        this.resultState.benefits = this.resultState.laborCost - this.resultState.cost;
        this.resultState.extra = ((this.state.hours + this.state.resultHours) / 100) * 85;
        this.resultState.benefitsPerYear = this.resultState.benefits * 12;

        this.renderResult();
    }

    private renderResult(): void {
        this.results.forEach(result => {
            const type = result.dataset.calculatorResult as calculatorResultKey;

            if (type == 'extra') {
                result.innerText = Math.round(this.resultState[type]).toString();
            } else {
                result.innerText = this.toAmount(this.resultState[type]);
            }

        });
        return;
    }

    private toFloat(value: string): number {
        const float = parseFloat(value);
        return isNaN(float) ? 0 : float;
    }

    private toAmount(amount: number): string {
        const parts = amount.toString().split('.');
        const decimal = parts[1] ? (parts[1][0] || '0') + (parts[1][1] || '0') : '00';

        return parts[0] + '.' + decimal;
    }
}